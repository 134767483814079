/*
 * HomeList css File
*/

.homelist {
    // float: left;
    header {
        h2 {
            font-family: FiraSans-Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 55px;
            line-height: 52px;
            /* identical to box height, or 95% */
            text-align: center;
            letter-spacing: 0.2px;
            color: #1b1d21;
            padding: 50px 0px;
        }
    }
    .listcontainer {
        width: 90%;
        margin: 0 auto;
        overflow: hidden;
        .min-height {
            min-height: 250px;
        }
        .imgbg {
            img {
                border-radius: 5px;
                background: #29abe2;
                height: 52px;
                width: 52px;
                padding: 10px;
                position: relative;
                top: 15px;
            }
        }
        span {
            // padding: 1rem;
            // float: left;
            label {
                font-family: OpenSans-Medium;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
            }
            p {
                font-family: OpenSans-Medium;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
            }
        }
    }
}
