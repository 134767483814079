/*
 * PrivacyPolicy css File
*/

.Privacypolicy {
    background-color: #eef3ff;
    //    width: 90%;
    //    margin: 0 auto;
    .headersection {
        // background: linear-gradient(135deg, #e2f8fa 0.59%, #29acd8 100%);
        height: 400px;
        align-items: center;
        display: flex;
        justify-content: center;
        // border-bottom-left-radius: 100%;
        // border-bottom-right-radius: 100%;
        background-image: url(../../images/banner/PrivacyPolicy.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        header {
            justify-content: center;
            align-items: center;
            text-align: center;
            h2 {
                font-family: FiraSans-Bold;
                font-style: normal;
                font-weight: bold;
                font-size: 38px;
                line-height: 48px;
                /* identical to box height, or 126% */

                text-align: center;
                letter-spacing: 1.425px;

                color: #ffffff;
            }
            p {
                font-family: OpenSans-Medium;
                font-style: normal;
                font-weight: normal;
                font-size: 17px;
                line-height: 27px;
                /* identical to box height, or 159% */

                text-align: center;
                letter-spacing: 0.6px;

                color: #ffffff;
            }
        }
    }
    .a4-page {
        padding: 2rem;

        .innercontant {
            width: 80%;
            min-height: 29.7cm;
            padding: 1cm;
            margin: 1cm auto;
            border: 1px #d3d3d3 solid;
            border-radius: 5px;
            background: white;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            margin-bottom: 2rem;
            .commondiv {
                padding: 1rem 0rem;
                label {
                    font-family: OpenSans-Bold;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 27px;
                    /* or 159% */

                    letter-spacing: 0.6px;

                    color: #77838f;
                }
                p {
                    font-family: OpenSans-Regular;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 27px;
                    /* or 159% */

                    letter-spacing: 0.6px;

                    color: #77838f;
                    margin: 0;
                }
            }
        }
        .supportclass {
            font-weight: 700;
            color: #337ab7;
            text-decoration: none;
        }
    }
}

@media (min-width: 20em) and (max-width: 40em) and (orientation: portrait) {
    .Privacypolicy {
        .headersection {
            height: 250px;
            header {
                h2 {
                    font-size: 28px;
                    line-height: 38px;
                }
                p {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }
        .a4-page {
            .innercontant {
                width: auto;
                padding: 1rem;
            }
        }
    }
}
@media (min-width: 30em) and (max-width: 60em) and (orientation: landscape) {
    .Privacypolicy {
        .headersection {
            height: 250px;
            header {
                h2 {
                    font-size: 28px;
                    line-height: 38px;
                }
                p {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }
        .a4-page {
            .innercontant {
                width: auto;
                // padding: 1rem;
            }
        }
    }
}
@media (min-width: 40em) and (max-width: 50em) and (orientation: portrait) {
    .Privacypolicy {
        .headersection {
            height: 250px;
            header {
                h2 {
                    font-size: 28px;
                    line-height: 38px;
                }
                p {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }
        .a4-page {
            .innercontant {
                width: auto;
                // padding: 1rem;
            }
        }
    }
}
