.main {
    .mainbg-color {
        width: 100%;
        height: 800px;
        background: #eef3ff;
        .sec-split {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0%;

            .flex {
                .left-flex {
                    padding: 0rem 4rem;

                    .sec-one {
                        // width: 750px;
                        font-family: FiraSans-Medium;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 60px;
                        line-height: 75px;
                        letter-spacing: 0.1875px;
                        color: #2d2d2d;
                        // margin: 0 auto;
                    }
                    .sec-two {
                        // width: 750px;
                        margin: 0 auto;
                        font-family: OpenSans-Medium;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 20px;
                        line-height: 36px;
                        color: #1b1d21;

                        color: #1b1d21;
                    }
                    .contactPageForm {
                        display: flex;
                        flex-direction: row;
                        box-sizing: border-box;
                        // background: #fff;
                        height: 50px;
                        border: 1px solid #b3bac512;
                        border-radius: 200px;
                        // width: 60%;
                        // padding: 5px;
                        margin-top: 20px;
                        // margin-left: 4rem;

                        .formBoxSection {
                            // width: 333.33px;
                            .contactPageInput {
                                // box-sizing: border-box;
                                // background: #fff;
                                // height: 40px;
                                // // width: 333.33px;
                                // border: 1px solid #b3bac512;
                                // width: 400px;
                                display: none;

                                color: #1b1d21;
                                font-size: 20px;
                                line-height: 15px;
                                // padding: 1.5rem;
                                outline: none;
                                font-family: OpenSans-Regular;
                                font-weight: normal;
                                mix-blend-mode: normal;
                                // margin-top: 20px;
                                flex-grow: 2;
                                border: none;
                                background: transparent;
                                padding: 20px;
                            }
                            ::placeholder {
                                /* Chrome, Firefox, Opera, Safari 10.1+ */
                                color: #1b1d21;
                                font-family: OpenSans-Regular;
                                font-size: 20px;
                                line-height: 21px;
                            }
                            .contactpageBtn {
                                background: linear-gradient(
                                    90deg,
                                    #29abe2 -0.02%,
                                    #29acd8 19.99%,
                                    #29aebd 54.99%,
                                    #28b292 99%,
                                    #28b295 100%
                                );
                                border-radius: 50px;
                                color: #ffffff;
                                padding: 10px;
                                font-family: OpenSans-Bold;
                                font-style: normal;
                                font-size: 14px;
                                width: 150px;
                                letter-spacing: 0.15em;
                                cursor: pointer;
                                justify-content: center;
                                align-items: center;
                                display: flex;
                            }
                        }
                    }
                }
            }

            .right-flex {
                display: flex;
                justify-content: center;
                height: 100%;
                align-items: center;
                padding: 0%;
                .sec-split-two {
                    position: relative;
                    width: 100%;
                    height: 800px;
                    left: 0px;
                    top: 0px;

                    background-image: url("../../images/banner/homebanner1.jpg");
                    background-position: initial;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 200px 0px 200px;
                }
            }
        }
    }
}

@media (min-width: 20em) and (max-width: 40em) and (orientation: portrait) {
    .main {
        .mainbg-color {
            .sec-split {
                .flex {
                    .left-flex {
                        padding: 0;
                        .sec-one {
                            width: auto;
                            font-size: 42px;
                            line-height: 50px;
                        }
                        .sec-two {
                            width: auto;
                            font-size: 18px;
                            line-height: 32px;
                            padding-top: 20px;
                        }
                        .contactPageForm {
                            width: 100%;
                            // padding: 5px;
                            margin-top: 20px;
                            margin-left: 0%;

                            .formBoxSection {
                                .contactPageInput {
                                    font-size: 14px;
                                    width: 200px;
                                    padding: 10px;
                                }
                                .contactpageBtn {
                                    width: 120px;
                                }
                            }
                        }
                    }
                }
                height: 100%;

                .right-flex {
                    display: none;
                }
            }
        }
    }
}
@media (min-width: 30em) and (max-width: 60em) and (orientation: landscape) {
    .main {
        .mainbg-color {
            .sec-split {
                height: 100%;
                .flex {
                    .left-flex {
                        .sec-one {
                            // width: 80%;
                            font-size: 42px;
                            line-height: 50px;
                        }
                        .sec-two {
                            // width: 80%;
                            font-size: 18px;
                            line-height: 32px;
                            padding-top: 20px;
                        }
                        .contactPageForm {
                            // width: 65%;
                            // padding: 5px;
                            margin-top: 20px;
                            // margin-left: 10%;

                            // .formBoxSection {
                            // }
                        }
                    }
                }

                .right-flex {
                    display: none;
                }
            }
        }
    }
}
@media (min-width: 40em) and (max-width: 50em) and (orientation: portrait) {
    .main {
        .mainbg-color {
            height: 650px;
            .sec-split {
                height: 100%;
                .flex {
                    .left-flex {
                        .sec-one {
                            // width: 80%;
                            font-size: 42px;
                            line-height: 50px;
                        }
                        .sec-two {
                            // width: 80%;
                            font-size: 18px;
                            line-height: 32px;
                            padding-top: 20px;
                        }
                        .contactPageForm {
                            // width: 22%;
                            padding: 5px;
                            margin-top: 20px;
                            // margin-left: 10%;
                            .formBoxSection {
                                justify-content: left;
                            }
                        }
                    }
                }

                .right-flex {
                    display: none;
                }
            }
        }
    }
}

@media (min-width: 50em) and (max-width: 80em) and (orientation: landscape) {
    .main {
        .mainbg-color {
            height: 650px;
            .sec-split {
                height: 100%;
                .flex {
                    .left-flex {
                        .sec-one {
                            // width: 80%;
                            font-size: 42px;
                            line-height: 50px;
                        }
                        .sec-two {
                            // width: 80%;
                            font-size: 18px;
                            line-height: 32px;
                            padding-top: 20px;
                        }
                        .contactPageForm {
                            // width: 25%;
                            padding: 5px;
                            margin-top: 20px;
                            // margin-left: 10%;
                            .formBoxSection {
                                justify-content: left;
                            }
                        }
                    }

                    
                }
                .right-flex {
                    display: none;
                }
            }
        }
    }
}
