/*
 * Works css File
*/

.worklist {
    // float: left;
    // width: 100%;
    // height: 100%;
    background: #fff;
    position: relative;
    // background-image: url(../../images/banner/Howitwork.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    header {
        h2 {
            font-family: FiraSans-Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 55px;
            line-height: 52px;
            /* identical to box height, or 95% */
            text-align: center;
            letter-spacing: 0.2px;
            color: #1b1d21;
            padding: 50px 0px;
        }
    }
    .centercontant {
        position: relative;
        padding: 2rem;

        .bgposition {
            // position: absolute;
            width: 50rem;
            height: 50rem;
            // left: 335px;
            // top: 50px;
            margin: 0 auto;
            /* Flowmed primary gradient */

            background: linear-gradient(
                90deg,
                #29abe2 -0.02%,
                #29acd8 19.99%,
                #29aebd 54.99%,
                #28b292 99%,
                #28b295 100%
            );
            mix-blend-mode: normal;
            opacity: 0.28;
            border-radius: 100%;
            display: flex;
            justify-content: center;

            p {
                // width: 50%;
                margin: 0px 30%;
                // height: 100%;
                font-family: FiraSans-Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 60px;
                line-height: 75px;
                letter-spacing: 0.1875px;
                color: #ffffff;
                // position: static;
                // float: left;
                /* text-align: center; */
                display: flex;
                align-items: center;
                justify-content: center;
    flex-direction: column;
            }
        }
    }
    .ddd {
        position: relative;
        .right-arrow {
            position: absolute;
            left: 93.13%;
            right: -60.01%;
            top: 60.24%;
            bottom: 28.38%;

            /* Flowmed primary gradient */

            background: linear-gradient(
                90deg,
                #29abe2 -0.02%,
                #29acd8 19.99%,
                #29aebd 54.99%,
                #28b292 99%,
                #28b295 100%
            );
            transform: rotate(-94.16deg);
        }
    }
}

@media (min-width: 20em) and (max-width: 40em) and (orientation: portrait) {
    .worklist {
        header{
            h2{
                font-size: 28px;
                line-height: 0;
            }
        }
        .centercontant {
            .bgposition {
                width: 20rem;
                height: 20rem;
                p {
                    font-size: 32px;
                    line-height: 40px;
                }
            }
        }
    }
}
@media (min-width: 30em) and (max-width: 60em) and (orientation: landscape) {
    .worklist {
        header{
            h2{
                font-size: 32px;
                line-height: 0;
            }
        }
        .centercontant {
            .bgposition {
                width: 30rem;
                height: 30rem;
                p {
                    font-size: 42px;
                    line-height: 50px;
                }
            }
        }
    }
}
@media (min-width: 40em) and (max-width: 50em) and (orientation: portrait) {
    .worklist {
        header{
            h2{
                font-size: 38px;
                line-height: 0;
            }
        }
        .centercontant {
            .bgposition {
                width: 40rem;
                height: 40rem;
                p {
                    font-size: 52px;
                    line-height: 60px;
                }
            }
        }
    }
}
