/*
 * Clients css File
*/

.Clientlist {
    // float: left;
    width: 100%;
    height: 100%;
    background: #f6fdff;
    position: relative;
    padding: 5rem 0rem;

    header {
        h2 {
            font-family: FiraSans-Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 55px;
            line-height: 52px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #1b1d21;
            padding: 50px 0px;
        }
    }

    .clientcontant {
        width: 90%;
        margin: 0 auto;
        display: flex;
        .clientparagape {
            .bg-box {
                background-color: #fff;
                padding: 2rem;
                border-radius: 10px;
                box-shadow: 0px 0px 7px 0px #ccc;
                flex-direction: column;
                justify-content: space-evenly;
                display: flex;

                p {
                    font-family: OpenSans-Regular;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 26px;
                    letter-spacing: 0.2px;
                    color: #838383;
                    min-height: 310px;
                    margin: 0;
                }

                label {
                    padding-top: 1rem;
                    justify-content: left;
                    display: flex;
                    align-items: center;
                    min-height: 70px;
                    // display: contents;
                    .circle {
                        width: 40px;
                        height: 40px;
                        float: left;
                        border-radius: 50%;
                        background: linear-gradient(
                            90deg,
                            #29abe2 -0.02%,
                            #29acd8 19.99%,
                            #29aebd 54.99%,
                            #28b292 99%,
                            #28b295 100%
                        );
                    }
                    .circletext {
                        font-family: OpenSans-bold;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 18px;
                        padding-left: 20px;
                        display: flex;
                        color: #222222;
                        padding-top: 5px;
                        width: 80%;
                    }
                }
            }
        }
    }
}

@media (min-width: 20em) and (max-width: 40em) and (orientation: portrait) {
    .Clientlist {
        header {
            h2 {
                font-size: 28px;
                line-height: 40px;
                padding: 50px 25px;
            }
        }
        .clientcontant {
            flex-direction: column;
            width: 100%;
        }
    }
}
@media (min-width: 30em) and (max-width: 60em) and (orientation: landscape) {
    .Clientlist {
        header {
            h2 {
                font-size: 28px;
                line-height: 20px;
                padding: 50px 25px;
            }
        }
        .clientcontant {
            flex-direction: column;
            width: 100%;
            .clientparagape {
                .bg-box {
                    p {
                        min-height: 0%;
                    }
                }
            }
        }
    }
}
@media (min-width: 40em) and (max-width: 50em) and (orientation: portrait) {
    .Clientlist {
        header {
            h2 {
                font-size: 28px;
                line-height: 20px;
                padding: 50px 25px;
            }
        }
        .clientcontant {
            flex-direction: column;
            width: 100%;
            .clientparagape {
                .bg-box {
                    p {
                        min-height: 0%;
                    }
                }
            }
        }
    }
}
