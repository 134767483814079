/*
 * Products css File
*/

.producthead {
    background: #b6e2f1;


    header {
        h2 {
            font-family: FiraSans-Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 55px;
            line-height: 52px;
            /* identical to box height, or 95% */
            text-align: center;
            letter-spacing: 0.2px;
            color: #1b1d21;
            padding: 50px 0px;
        }
    }

    .productlist:nth-of-type(even) {
        background-color: #28acda;

        h3 {
            color: #FFFFFF;
        }

        p {
            color: #FFFFFF !important;
        }
    }

    .productlist {

        .listpadding {
            width: 90%;
            margin: 0 auto;
            overflow: hidden;
            height: 45rem;
        }

        .flexdirection {
            flex-direction: column;
            display: flex;
            justify-content: center;
            height: 100%;


            h3 {
                font-size: 60px;
                font-weight: 500;
                font-family: FiraSans-Medium;
                font-style: normal;
                letter-spacing: 0.1875px;

            }

            p {
                // margin-top: 0;
                margin-bottom: 1rem;
                font-family: OpenSans-Medium;
                font-style: normal;
                color: #1b1d21;
                font-weight: 400;
                font-size: 20px;
                line-height: 36px;
                img{
                    width: 110px;
                }

            }
        }

        .right_img {
            display: flex;
            vertical-align: middle;
            align-items: center;
            justify-content: center;

            span {
                width: 37.5rem;
                height: 25.5rem;

                img {
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}



@media (min-width: 20em) and (max-width: 40em) and (orientation: portrait) {
    .producthead {
        header {
            h2 {
                font-size: 28px;
                line-height: 0;
            }
        }

        .productlist {
            padding: 2rem 0rem;

            .listpadding {
                height: 100%;
            }
            .right_img {
    
                span {
    
                    width: 19.5rem;
                    height: 15.5rem;
                }
            }
        }
    }
}

@media (min-width: 30em) and (max-width: 60em) and (orientation: landscape) {
    .producthead {
        // padding: 1rem;

        header {
            h2 {
                font-size: 32px;
                line-height: 0;
            }
        }

        .productlist {
            padding: 2rem 0rem;

            .listpadding {
                height: 100%;
            }

            .right_img {
    
                span {
                    width: 35.5rem;
                    height: 22.5rem;
                }
            }
        }
    }
}

@media (min-width: 40em) and (max-width: 50em) and (orientation: portrait) {
    .producthead {
        header {
            h2 {
                font-size: 38px;
                line-height: 0;
            }
        }

        .productlist {
            padding: 2rem 0rem;

            .listpadding {
                height: 100%;
            }
            .right_img {
    
                span {
    
                    width: 19.5rem;
                    height: 15.5rem;
                }
            }
        }
    }
}

@media (min-width: 60em) and (max-width: 80em) and (orientation: landscape) {
    .producthead {
        // padding: 1rem;

        header {
            h2 {
                font-size: 32px;
                line-height: 0;
            }
        }

        .productlist {
            padding: 2rem 0rem;

            .listpadding {
                height: 100%;
            }

            .right_img {
    
                span {
                    width: 35.5rem;
                    height: 22.5rem;
                }
            }
        }
    }
}
@media (min-width: 60em) and (max-width: 80em) and (orientation: portrait) {
    .producthead {
        // padding: 1rem;

        header {
            h2 {
                font-size: 32px;
                line-height: 0;
            }
        }

        .productlist {
            padding: 2rem 0rem;

            .listpadding {
                height: 100%;
            }

            .right_img {
    
                span {
                    width: 35.5rem;
                    height: 22.5rem;
                }
            }
        }
    }
}