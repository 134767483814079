
@font-face {
  font-family: Lato-Regular;
  src: local("Lato-Regular"), url(./fonts/Lato/Lato-Regular.ttf) format("truetype");
  font-style: normal;
}
@font-face {
  font-family: Lato-Italic;
  src: local("Lato-Italic"), url(./fonts/Lato/Lato-Italic.ttf) format("truetype");
  font-style: normal;
}
@font-face {
  font-family: Lato-Bold;
  src: local("Lato-Bold"), url(./fonts/Lato/Lato-Bold.ttf) format("truetype");
  font-style: normal;
}



@font-face {
    font-family: FiraSans-Bold;
    src: local("FiraSans-Bold"), url(./fonts/Fira_Sans/FiraSans-Bold.ttf) format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: FiraSans-Medium;
    src: local("FiraSans-Medium"), url(./fonts/Fira_Sans/FiraSans-Medium.ttf) format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: FiraSans-Regular;
    src: local("FiraSans-Regular"), url(./fonts/Fira_Sans/FiraSans-Regular.ttf) format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: Raleway-Bold;
    src: local("Raleway-Bold"), url(./fonts/Raleway/Raleway-Bold.ttf) format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: Raleway-Medium;
    src: local("Raleway-Medium"), url(./fonts/Raleway/Raleway-Medium.ttf) format("truetype");
    font-style: normal;
  }
// /* OpenSans*/

@font-face {
  font-family: OpenSans-Bold;
  src: local("OpenSans-Bold"), url(./fonts/OpenSans/OpenSans-Bold.ttf) format("truetype");
  font-style: normal;
}
@font-face {
    font-family: OpenSans-BoldItalic;
    src: local("OpenSans-BoldItalic"), url(./fonts/OpenSans/OpenSans-BoldItalic.ttf) format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: OpenSans-ExtraBold;
    src: local("OpenSans-ExtraBold"), url(./fonts/OpenSans/OpenSans-ExtraBold.ttf) format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: OpenSans-ExtraBoldItalic;
    src: local("OpenSans-ExtraBoldItalic"), url(./fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf) format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: OpenSans-Italic;
    src: local("OpenSans-Italic"), url(./fonts/OpenSans/OpenSans-Italic.ttf) format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: OpenSans-Light;
    src: local("OpenSans-Light"), url(./fonts/OpenSans/OpenSans-Light.ttf) format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: OpenSans-LightItalic;
    src: local("OpenSans-LightItalic"), url(./fonts/OpenSans/OpenSans-LightItalic.ttf) format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: OpenSans-Medium;
    src: local("OpenSans-Medium"), url(./fonts/OpenSans/OpenSans-Medium.ttf) format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: OpenSans-MediumItalic;
    src: local("OpenSans-MediumItalic"), url(./fonts/OpenSans/OpenSans-MediumItalic.ttf) format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: OpenSans-Regular;
    src: local("OpenSans-Regular"), url(./fonts/OpenSans/OpenSans-Regular.ttf) format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: OpenSans-SemiBold;
    src: local("OpenSans-SemiBold"), url(./fonts/OpenSans/OpenSans-SemiBold.ttf) format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: OpenSans-SemiBoldItalic;
    src: local("OpenSans-SemiBoldItalic"), url(./fonts/OpenSans/OpenSans-SemiBoldItalic.ttf) format("truetype");
    font-style: normal;
  }

/* ROBOTO */

@font-face {
  font-family: Roboto-Black;
  src: local("Roboto-Black"), url(./fonts/Roboto/Roboto-Black.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Roboto-BlackItalic;
  src: local("Roboto-BlackItalic"), url(./fonts/Roboto/Roboto-BlackItalic.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Roboto-Bold;
  src: local("Roboto-Bold"), url(./fonts/Roboto/Roboto-Bold.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Roboto-BoldItalic;
  src: local("Roboto-BoldItalic"), url(./fonts/Roboto/Roboto-BoldItalic.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Roboto-Italic;
  src: local("Roboto-Italic"), url(./fonts/Roboto/Roboto-Italic.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Roboto-Light;
  src: local("Roboto-Light"), url(./fonts/Roboto/Roboto-Light.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Roboto-LightItalic;
  src: local("Roboto-LightItalic"), url(./fonts/Roboto/Roboto-LightItalic.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Roboto-Medium;
  src: local("Roboto-Medium"), url(./fonts/Roboto/Roboto-Medium.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Roboto-MediumItalic;
  src: local("Roboto-MediumItalic"), url(./fonts/Roboto/Roboto-MediumItalic.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: local("Roboto"), url(./fonts/Roboto/Roboto-Regular.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Roboto-Thin;
  src: local("Roboto-Thin"), url(./fonts/Roboto/Roboto-Thin.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Roboto-ThinItalic;
  src: local("Roboto-ThinItalic"), url(./fonts/Roboto/Roboto-ThinItalic.ttf) format("truetype");
  font-style: normal;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: pink;
}
::-moz-placeholder { /* Firefox 19+ */
  color: pink;
}
:-ms-input-placeholder { /* IE 10+ */
  color: pink;
}
:-moz-placeholder { /* Firefox 18- */
  color: pink;
}