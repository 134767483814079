/*
 * Turst css File
*/

.turstlist {
    background: #f6fdff;
    position: relative;
    padding: 5rem 0rem;
    header {
        h2 {
            font-family: FiraSans-Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 55px;
            line-height: 52px;
            /* identical to box height, or 95% */
            text-align: center;
            letter-spacing: 0.2px;
            color: #1b1d21;
            padding: 50px 0px;
        }
    }
    .turstcontant {
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        padding: 2rem 0rem;

        .logoimage {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            .logo-box {
                // width: 90%;
                // margin: 0 auto;
                // text-align: center;
                // width: 80%;
                // height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1rem;
            }
            a {
                cursor: pointer;
                width: 90%;

                img {
                    width: 100%;
                    // height: 60px;
                }
            }
        }
    }
    .boximage-parent {
        // float: left;
        display: flex;
        justify-content: space-around;
        .boximage {
            vertical-align: middle;
            display: flex;
            justify-content: center;
            // flex: auto;
            .box {
                width: 118px;
                height: 87px;
                background: #e2eff3;
            }
        }
    }
}

@media (min-width: 20em) and (max-width: 40em) and (orientation: portrait) {
    .turstlist {
        padding: 3rem 0rem;

        header {
            h2 {
                font-size: 28px;
                line-height: 40px;
                padding: 10px 25px;
            }
        }
        .turstcontant {
            flex-direction: column;
            .logoimage {
                flex-direction: column;
                .logo-box{
                    padding: 1rem 2rem;
                }
            }
        }
        .boximage-parent {
            flex-direction: column;
        }
    }
}
@media (min-width: 30em) and (max-width: 60em) and (orientation: landscape) {
    .turstlist {
        padding: 3rem 0rem;
        header {
            h2 {
                font-size: 32px;
                line-height: 40px;
                padding: 20px 25px;
            }
        }
        .turstcontant {
            flex-direction: column;
            .logoimage {
                flex-direction: column;
                align-items: center;
            }
        }
        .boximage-parent {
            flex-direction: column;
        }
    }
}
@media (min-width: 40em) and (max-width: 50em) and (orientation: portrait) {
    .turstlist {
        padding: 3rem 0rem;
        header {
            h2 {
                font-size: 42px;
                line-height: 40px;
                padding: 25px 25px;
            }
        }
        // .turstcontant{
        //     flex-direction: column;

        // }
        // .boximage-parent{
        //     flex-direction: column;
        // }
    }
}

@media (min-width: 20em) and (max-width: 40em) and (orientation: portrait) {
}
@media (min-width: 30em) and (max-width: 60em) and (orientation: landscape) {
}
@media (min-width: 40em) and (max-width: 50em) and (orientation: portrait) {
}
