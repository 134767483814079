/*
 * Partners css File
*/

.partners {
    background: #f6fdff;
    position: relative;
    padding: 5rem 0rem;
    header {
        h2 {
            font-family: FiraSans-Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 55px;
            line-height: 52px;
            /* identical to box height, or 95% */
            text-align: center;
            letter-spacing: 0.2px;
            color: #1b1d21;
            padding: 50px 0px;
        }
    }
    .add-logo {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .logo {
            text-align: center;
            padding: 1rem;
            justify-content: center;
            width: 240px;
            height: 200px;
            display: flex;
            align-items: center;
            img {
                width: 80%;
            }
        }
        .oracle{
            img{
                width: 100% !important;
            }
        }
    }
}

@media (min-width: 20em) and (max-width: 40em) and (orientation: portrait) {
    .partners {
        header{
            h2 {
                font-size: 28px;
                line-height: 40px;
                padding: 10px 25px;
            }
        }

        .add-logo {
            .logo {
                height: auto;
                img {
                    width: 80%;
                }
            }
        }
    }
}
@media (min-width: 30em) and (max-width: 60em) and (orientation: landscape) {
    .partners {

    header h2 {
        font-size: 32px;
        line-height: 40px;
        padding: 20px 25px;
    }
}
}
@media (min-width: 40em) and (max-width: 50em) and (orientation: portrait) {
}
