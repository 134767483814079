.navbar {
    height: 80px;
    display: flex;
    width: 95%;
    padding: 0;
    margin: 0 auto;

    .navbar-container {
        height: 80px;
        padding: 10px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        .navlogo {
            // width: 20%;
            // display: flex;
            // align-items: center;
            // justify-content: space-around;
            padding: 1rem;

            a {
                // img {
                //     width: 60%;
                //     height: 50px;
                // }
            }
        }

        ul {
            // width: 80%;
            list-style: none;
            padding-right: 1px;
            margin: 0;
            color: #000000;

            li {
                display: flex;
                justify-content: flex-end;
                margin: 0;
                height: 100%;
                align-items: center;
                cursor: pointer;

                a {
                    font-family: OpenSans-Medium;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 28px;
                    /* identical to box height, or 175% */

                    color: #000000;
                    margin-left: 3rem;
                }

                .unerline {
                    width: 16px;
                    height: 4px;

                    /* Flowmed primary gradient */

                    background: linear-gradient(90deg,
                            #29abe2 -0.02%,
                            #29acd8 19.99%,
                            #29aebd 54.99%,
                            #28b292 99%,
                            #28b295 100%);
                    border-radius: 32px;
                    margin: 0;
                }

                .btn {
                    // width: 140px;
                    padding: 20px 30px;
                    height: 40px;
                    justify-content: center;
                    background: linear-gradient(90deg,
                            #29abe2 -0.02%,
                            #29acd8 19.99%,
                            #29aebd 54.99%,
                            #28b292 99%,
                            #28b295 100%);
                    border-radius: 50px;
                    font-family: OpenSans-Medium;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.15em;

                    color: #ffffff;
                    margin-right: 0rem;
                }
            }
        }
    }
}

.navbar-collapse {
    z-index: 999;
}

.mobile-bg {
    background-color: #ffffff;
    font-family: OpenSans-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    padding: 1rem;
    width: 50%;
    float: right;
    /* identical to box height, or 175% */

    color: #000000;
    align-items: flex-end;
}

.menubar .mobile .navbar {
    display: none;
}

.mobile {
    .container {
        height: 80px;

        a {
            justify-content: center;
            align-items: center;
            display: flex;
            height: 100%;
        }

        button {
            align-items: center;
            justify-content: center;
            display: flex;
            color: #00000099;
            border-color: #00000099;
        }
    }
}


@media (min-width: 20em) and (max-width: 40em) and (orientation: portrait) {
    .menubar .navbar {
        display: none;
    }

    .menubar .mobile {
        .container {
            padding: 10px 20px;
            background: #fff;
        }

        .navbar {
            display: block;
        }
    }
}

@media (min-width: 30em) and (max-width: 60em) and (orientation: landscape) {
    .bg-light {
        background-color: #fff !important;
    }

    .menubar .navbar {
        display: none;
    }

    .menubar .mobile {
        .container {
            // padding: 10px 20px;
            background: #fff;

        }

        .navbar {
            display: block;
        }
    }
}

@media (min-width: 40em) and (max-width: 50em) and (orientation: portrait) {
    .menubar {
        .navbar {
            display: block;

            .navbar-container {
                .navlogo {
                    // width: 16%;
                    text-align: center;

                    a {
                        img {
                            width: 100%;
                        }
                    }
                }

                ul {
                    padding-left: 0px;
                    // width: 85%;
                    li {
                        a {
                            font-size: 12px;
                            margin-left: 1rem;
                        }

                        .btn {
                            padding: 15px 20px;
                            height: 35px;
                            font-size: 12px;
                        }
                        .dropdown{
                            .dropbtn{
                                font-size: 12px;
                                margin-left: 1rem;
                                padding: 0px;
                            }
                        } 
                    }
                }
            }
        }
    }
}

.topnav {
    overflow: hidden;
    background-color: #333;
}

.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.active {
    background-color: #04AA6D;
    color: white;
}

.topnav .icon {
    display: none;
}

.dropdown {
    float: left;
}

.dropdown .dropbtn {
    font-family: OpenSans-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    margin-left: 3rem;
    border: none;
    outline: none;
    padding: 14px 16px;
    background-color: inherit;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0px;
}

.dropdown-content ul {
    padding: 0px;
}

.dropdown-content ul li {
    display: block !important;
}

.dropdown-content a {
    font-size: 13px !important;
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: right;
    margin: 0px !important;
}

.dropdown-content a:hover {
    background-color: #ddd;
    color: black;
}

.dropdown:hover .dropdown-content {
    display: block;
}

@media screen and (max-width: 600px) {

    .topnav a:not(:first-child),
    .dropdown .dropbtn {
        display: none;
    }

    .topnav a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {
        position: relative;
    }

    .topnav.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }

    .topnav.responsive .dropdown {
        float: none;
    }

    .topnav.responsive .dropdown-content {
        position: relative;
    }

    .topnav.responsive .dropdown .dropbtn {
        display: block;
        width: 100%;
        text-align: left;
    }
}


@media (min-width: 60em) and (max-width: 70em) and (orientation: landscape) {

    .menubar {
        .navbar {
            display: block;

            .navbar-container {
                .navlogo {
                    // width: 16%;
                    text-align: center;

                    a {
                        img {
                            width: 100%;
                        }
                    }
                }

                ul {

                    // width: 85%;
                    li {
                        a {
                            font-size: 14px;
                            margin-left: 1.5rem;
                        }

                        .btn {
                            padding: 20px;
                            height: 35px;
                            font-size: 14px;
                        }

                        .dropdown{
                            .dropbtn{
                                font-size: 14px;
                                margin-left: 1.5rem;
                                padding: 0px;
                            }
                        } 
                    }
                }
            }
        }
    }

}