.aboutlist {
    #more {
        display: none;
    }

    .aboutbg-color {
        width: 100%;
        height: 800px;
        background: #eef3ff;

        .sec-split {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0%;

            .flex {
                .left-flex {
                    padding: 0rem 4rem;

                    .sec-one {
                        // width: 750px;
                        font-family: FiraSans-Medium;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 60px;
                        line-height: 75px;
                        letter-spacing: 0.1875px;
                        color: #2d2d2d;
                        // margin: 0 auto;
                    }

                    .sec-two {
                        // width: 750px;
                        margin: 0 auto;
                        font-family: OpenSans-Medium;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 34px;
                        color: #1b1d21;

                        color: #1b1d21;

                        p {
                            span {
                                color: #29aebd;
                                font-weight: bold;
                                font-size: 22px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .right-flex {
                display: flex;
                justify-content: center;
                height: 100%;
                align-items: center;
                padding: 0%;

                .sec-split-two {
                    position: relative;
                    width: 100%;
                    height: 800px;
                    left: 0px;
                    top: 0px;

                    background-image: url("../../images/banner/homebanner1.jpg");
                    background-position: initial;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 200px 0px 200px;
                }
            }
        }
    }
}

.aboutleader {

    // float: left;
    header {
        h2 {
            font-family: FiraSans-Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 55px;
            line-height: 52px;
            /* identical to box height, or 95% */
            text-align: center;
            letter-spacing: 0.2px;
            color: #1b1d21;
            padding: 50px 0px;
        }
    }

    .listcontainer {
        width: 90%;
        margin: 0 auto;
        overflow: hidden;

        .min-height {
            min-height: 250px;
        }

        .imgbg {
            // background-color: #eef3ff;
            border-radius: 15px;
            width: 338px;
            height: 100px;
            float: left;

            img {
                border-radius: 15px;
                width: 100%;
                height: 100%;
                // display: none;
            }

            .history {
                position: absolute;
                bottom: 45px;
                width: 338px;
                padding: 15px;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                background: linear-gradient(90deg, #29ABE2 -0.02%, #29ACD8 19.99%, #29AEBD 54.99%, #28B292 99%, #28B295 100%);
                color: #fff;

                label {
                    font-family: OpenSans-Medium;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 38px;
                    text-transform: uppercase;
                }

                p {
                    font-family: OpenSans-Medium;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    letter-spacing: 0.1875px;

                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 5px;
                        float: right;
                        display: block;
                    }
                }
            }
        }

        span {

            // padding: 1rem;
            // float: left;
            label {
                font-family: OpenSans-Medium;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
            }

            p {
                font-family: OpenSans-Medium;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
            }
        }
    }


}


.popup-content {
    //     margin: auto;
    //     background: #fff;
    width: 45% !important;
    //     padding: 20px !important;
    //     border: 1px solid #d7d7d7;
    // top: 1400px !important;
        left: 400px !important;
    //     pointer-events: initial !important;
}

.popup-overlay {
    background: rgba(0, 0, 0, .5) !important;
}

[role=tooltip].popup-content {
    width: 100%;
    box-shadow: 0 0 3pxrgba(0, 0, 0, .16);
    border-radius: 15px !important;
    background-color: #eef3ff;
}

// html[data-theme='light'] .popup-content {
//     background-color: #fff;
//     color: #000;
//     border: 1px solid #d7d7d7;
// }

// html[data-theme='dark'] .popup-arrow>svg {
//     color: rgb(41, 45, 62);
//     stroke-width: 2px;
//     stroke: #9a9595;
//     stroke-dasharray: 30px;
//     stroke-dashoffset: -54px;
// }

// html[data-theme='light'] .popup-arrow>svg {
//     color: #fff;
//     stroke-width: 2px;
//     stroke: #d7d7d7;
//     stroke-dasharray: 30px;
//     stroke-dashoffset: -54px;
// }
.close {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    top: 10px;
    position: absolute;
    right: 10px;
    background: linear-gradient(90deg, #29abe2 -0.02%, #29acd8 19.99%, #29aebd 54.99%, #28b292 99%, #28b295 100%);
    padding: 5px;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    text-align: center;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    color: #fff !important;
    opacity: 1;
}

.close:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}
.popup-arrow{
    display: none;
}

.Popupmodel {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    // background: #fff;

    .img_contant {
        img {
            width: 250px;
            min-height: 250px;
        }
    }

    .leader_contant {
        label {
            font-size: 18px;
            line-height: 36px;
            color: #000;
            text-transform: uppercase;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            color: #000;
            padding-bottom: 15px;
        }
    }
}

@media (min-width: 20em) and (max-width: 40em) and (orientation: portrait) {
    .aboutlist {
        .aboutbg-color {
            .sec-split {
                .flex {
                    .left-flex {
                        padding: 0;

                        .sec-one {
                            width: auto;
                            font-size: 42px;
                            line-height: 50px;
                        }

                        .sec-two {
                            width: auto;
                            font-size: 14px;
                            line-height: 22px;
                            padding-top: 20px;
                        }
                    }
                }

                height: 100%;

                .right-flex {
                    display: none;
                }
            }
        }
    }

    .aboutleader {
        .listcontainer {
            .imgbg {
                width: 325px;
                .history {
                    bottom: 15px;
                    width: 325px;
                }
            }
        }
    }

    .popup-content {
        left: 10.746px !important;
        width: 95% !important;

        .Popupmodel {
            flex-direction: column;
        }
    }

}

@media (min-width: 30em) and (max-width: 60em) and (orientation: landscape) {
    .aboutlist {
        .aboutbg-color {
            .sec-split {
                height: 100%;

                .flex {
                    .left-flex {
                        .sec-one {
                            // width: 80%;
                            font-size: 42px;
                            line-height: 50px;
                        }

                        .sec-two {
                            // width: 80%;
                            font-size: 18px;
                            line-height: 32px;
                            padding-top: 20px;
                        }
                    }
                }

                .right-flex {
                    display: none;
                }
            }
        }
    }

    .popup-content {
        left: 10.746px !important;
        width: 97% !important;

        .Popupmodel {
            flex-direction: column;
        }
    }
}

@media (min-width: 40em) and (max-width: 50em) and (orientation: portrait) {
    .aboutlist {
        .aboutbg-color {
            height: 650px;

            .sec-split {
                height: 100%;

                .flex {
                    .left-flex {
                        .sec-one {
                            // width: 80%;
                            font-size: 42px;
                            line-height: 50px;
                        }

                        .sec-two {
                            // width: 80%;
                            font-size: 18px;
                            line-height: 32px;
                            padding-top: 20px;
                        }
                    }
                }

                .right-flex {
                    display: none;
                }
            }
        }
    }

    .popup-content {
        left: 10.746px !important;
        width: 97% !important;

        .Popupmodel {
            flex-direction: row;
        }
    }
}

@media (min-width: 50em) and (max-width: 80em) and (orientation: landscape) {
    .aboutlist {
        .aboutbg-color {
            height: 650px;

            .sec-split {
                height: 100%;

                .flex {
                    .left-flex {
                        .sec-one {
                            // width: 80%;
                            font-size: 42px;
                            line-height: 50px;
                        }

                        .sec-two {
                            // width: 80%;
                            font-size: 18px;
                            line-height: 32px;
                            padding-top: 20px;
                        }
                    }


                }

                .right-flex {
                    display: none;
                }
            }
        }
    }

    .popup-content {
        // left: 10.746px !important;
        // width: 97% !important;

        .Popupmodel {
            flex-direction: row;
        }
    }
}