/*
 * Contactus css File
*/

.Contactus {
    .LeftContact {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        .Address {
            min-width: 300px;
            cursor: pointer;
            h4 {
                margin: 15px 0px;
                font-size: 25px;
                color: #1e384d;
                position: relative;
                font-weight: 600;
                font-family: FiraSans-Regular;

                .mapicon{
                    padding-left: 10px;
                    color: #4db546;

                }
                .activecolor {
                    opacity: initial;
                }

                .deactivecolor {
                    opacity: 0.3;
                }
            }
            .add {
                align-items: center;
                margin-bottom: 15px;
                i {
                    width: 40px;
                    height: 40px;
                    background: #06b8c7;
                    color: #fff;
                    font-size: 18px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                }
                span {
                    font-family: OpenSans-Regular;
                }
            }
        }
        .socialMedia {
            margin: 30px 0px;
            display: flex;
            min-width: 300px;
            justify-content: flex-start;

            .youtube {
                color: #cd201f;
            }
            .twitter {
                color: #55acee;
            }
            .linkedin {
                color: #0077b5;
            }
            .facebook {
                color: #3b5998;
            }
            a {
                text-decoration: none;
                outline: none;
                font-size: 22px;
                margin-right: 20px;
                cursor: pointer;

                i {
                    font-size: 32px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
            .fab,
            .far {
                font-weight: 400;
            }
        }
    }
}

@media (min-width: 20em) and (max-width: 40em) and (orientation: portrait) {
    .mobileflex {
        flex-direction: column;
    }
}
@media (min-width: 30em) and (max-width: 60em) and (orientation: landscape) {
    .mobileflex {
        flex-direction: column;
    }
}
@media (min-width: 40em) and (max-width: 50em) and (orientation: portrait) {
    .mobileflex {
        flex-direction: column;
    }
}

// MAP Code
#map {
    position: relative;
    height: 500px;
    width: 100%;
}

@media (max-width: 375px) {
    #map {
        margin-bottom: 0px;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023.9px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    #map {
        margin-bottom: 50px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    #map {
        margin-bottom: 50px;
    }
}
.gMap {
    width: 100%;
    height: 500px;
}
