/*
 * Videos css File
*/
video::-internal-media-controls-download-button { 
    display:none; 
} 

.video {
    
    .controlsection {
        display: flex;
        justify-content: center;
        padding-bottom: 2rem;
        .video-size {
            width: 60% !important;
            height: 50% !important;
        }
        .video-size:focus-visible {
            border: 0px;
        }
    }
}

@media (min-width: 20em) and (max-width: 40em) and (orientation: portrait) {
    .video {
        .controlsection {
            .video-size {
                height: 200px !important;
            width: 100% !important;

            }
        }
    }
}
@media (min-width: 30em) and (max-width: 60em) and (orientation: landscape) {
    .video {
        .controlsection {
            .video-size {
                height: 50%;
            width: 100% !important;

            }
        }
    }
}
@media (min-width: 40em) and (max-width:60em) and (orientation: portrait){
    .video {
        .controlsection {
            .video-size {
                height: 50%;
            width: 100% !important;

            }
        }
    }
}
