.solutionlist {
    .solutionbg-color {
        width: 100%;
        height: 800px;
        background-position: initial;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("../../images/banner/solutionbannerbg.png");

        .sec-split {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0%;

            .flex {
                .left-flex {
                    padding: 0rem 4rem;

                    .sec-one {
                        // width: 750px;
                        font-family: FiraSans-Medium;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 60px;
                        line-height: 75px;
                        letter-spacing: 0.1875px;
                        color: #fff;
                        // margin: 0 auto;
                    }

                    .sec-two {
                        // width: 750px;
                        margin: 0 auto;
                        font-family: OpenSans-Medium;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 20px;
                        line-height: 36px;
                        color: #fff;
                    }

                    .contactPageForm {
                        display: flex;
                        flex-direction: row;
                        box-sizing: border-box;
                        height: 50px;
                        border: 1px solid #b3bac512;
                        border-radius: 200px;
                        margin-top: 20px;

                        .formBoxSection {

                            .contactpageBtn {
                                background: #fff;
                                border-radius: 50px;
                                color: #28aec4;
                                padding: 10px;
                                font-family: OpenSans-Bold;
                                font-style: normal;
                                font-size: 14px;
                                width: 150px;
                                letter-spacing: 0.15em;
                                cursor: pointer;
                                justify-content: center;
                                align-items: center;
                                display: flex;
                            }
                        }
                    }
                }
            }

            .right-flex {
                display: flex;
                justify-content: center;
                height: 100%;
                align-items: center;
                padding: 0%;

                .sec-split-two {
                    position: relative;
                    width: 100%;
                    height: 800px;
                    left: 0px;
                    top: 0px;

                    background-image: url("../../images/banner/HIW_Women.png");
                    background-position: initial;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
    }
}

.solutionleader {

    // float: left;
    header {
        h2 {
            font-family: FiraSans-Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 55px;
            line-height: 52px;
            /* identical to box height, or 95% */
            text-align: center;
            letter-spacing: 0.2px;
            color: #1b1d21;
            padding: 50px 0px;
            margin: 0;
            padding-bottom: 20px;
        }
    }

    .listcontainer {
        width: 60%;
        margin: 0 auto;
        overflow: hidden;
        padding-bottom: 30px;
        // background-color: rgba(255, 255, 255, 0.95);
        // box-shadow: 0 0px 29px 0 #00000021;
        // border-radius: 15px;
        // margin-top: 50px;
        // display: flex;

        .usecaselist {
            border-radius: 15px;
            background-color: rgba(255, 255, 255, 0.95);
            box-shadow: 0 0px 29px 0 #00000021;
            width: 370px;
            min-height: 500px;
            margin: 0 auto;
            margin-top: 40px;
            margin-bottom: 40px;

            p {
                img {
                    width: 100%;
                }
            }

            h2 {
                // text-align: center;
                font-size: 18px;
                line-height: 26px;
                padding: 27px;
                margin: 0;
            }

            .img_contant {
                padding: 0px 27px;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
}


@media (min-width: 20em) and (max-width: 40em) and (orientation: portrait) {
    .solutionlist {
        .solutionbg-color {
            .sec-split {
                .flex {
                    .left-flex {
                        padding: 0;

                        .sec-one {
                            width: auto;
                            font-size: 42px;
                            line-height: 50px;
                        }

                        .sec-two {
                            width: auto;
                            font-size: 18px;
                            line-height: 32px;
                            padding-top: 20px;
                        }

                        .contactPageForm {
                            width: 100%;
                            // padding: 5px;
                            margin-top: 20px;
                            margin-left: 0%;

                            .formBoxSection {
                                .contactPageInput {
                                    font-size: 14px;
                                    width: 200px;
                                    padding: 10px;
                                }

                                .contactpageBtn {
                                    width: 120px;
                                }
                            }
                        }
                    }
                }

                height: 100%;

                .right-flex {
                    display: none;
                }
            }
        }
    }

    .solutionleader {

        header {
            h2 {
                font-size: 36px;
                line-height: 36px;
            }
        }

        .listcontainer {
            width: 100%;
            padding-bottom: 20px;


            .usecaselist {
            width: 100%;
            }
        }
    }
}



@media (min-width: 30em) and (max-width: 60em) and (orientation: landscape) {
    .solutionlist {
        .solutionbg-color {
            .sec-split {
                height: 100%;

                .flex {
                    .left-flex {
                        .sec-one {
                            // width: 80%;
                            font-size: 42px;
                            line-height: 50px;
                        }

                        .sec-two {
                            // width: 80%;
                            font-size: 18px;
                            line-height: 32px;
                            padding-top: 20px;
                        }

                        .contactPageForm {
                            // width: 65%;
                            // padding: 5px;
                            margin-top: 20px;
                            // margin-left: 10%;

                            // .formBoxSection {
                            // }
                        }
                    }
                }

                .right-flex {
                    display: none;
                }
            }
        }
    }

    .solutionleader {

        header {
            h2 {
                font-size: 36px;
                line-height: 36px;
            }
        }

        .listcontainer {
            width: 100%;
            padding-bottom: 20px;

            .usecaselist {
                width: 100%;
            }
        }
    }
}

@media (min-width: 40em) and (max-width: 50em) and (orientation: portrait) {
    .solutionlist {
        .solutionbg-color {
            height: 650px;

            .sec-split {
                height: 100%;

                .flex {
                    .left-flex {
                        .sec-one {
                            // width: 80%;
                            font-size: 42px;
                            line-height: 50px;
                        }

                        .sec-two {
                            // width: 80%;
                            font-size: 18px;
                            line-height: 32px;
                            padding-top: 20px;
                        }

                        .contactPageForm {
                            // width: 22%;
                            padding: 5px;
                            margin-top: 20px;

                            // margin-left: 10%;
                            .formBoxSection {
                                justify-content: left;
                            }
                        }
                    }
                }

                .right-flex {
                    display: none;
                }
            }
        }
    }

    .solutionleader {

        header {
            h2 {
                font-size: 36px;
                line-height: 36px;
            }
        }

        .listcontainer {
            width: 100%;
            padding-bottom: 20px;

            .usecaselist {
                width: 320px;

            }
        }
    }
}

@media (min-width: 50em) and (max-width: 80em) and (orientation: landscape) {
    .solutionlist {
        .solutionbg-color {
            height: 650px;

            .sec-split {
                height: 100%;

                .flex {
                    .left-flex {
                        .sec-one {
                            // width: 80%;
                            font-size: 42px;
                            line-height: 50px;
                        }

                        .sec-two {
                            // width: 80%;
                            font-size: 18px;
                            line-height: 32px;
                            padding-top: 20px;
                        }

                        .contactPageForm {
                            // width: 25%;
                            padding: 5px;
                            margin-top: 20px;

                            // margin-left: 10%;
                            .formBoxSection {
                                justify-content: left;
                            }
                        }
                    }


                }

                .right-flex {
                    display: none;
                }
            }
        }
    }

    .solutionleader {

        header {
            h2 {
                font-size: 36px;
                line-height: 36px;
            }
        }

        .listcontainer {
            width: 100%;
            padding-bottom: 20px;

            .usecaselist {
                width: 400px;


            }
        }
    }
}