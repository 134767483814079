/*
 * Footer css File
*/
.footer {
    padding: 5rem 0rem;
    width: 90%;
    margin: 0 auto;
    .footer-section {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: initial;
       
        ul {
            list-style: none;
            padding: 0;
            line-height: 40px;
            font-family: FiraSans-Regular;
            li {
                a {
                    color: #1b1d21;
                    cursor: pointer;
                }
            }
        }
        .Firstchild {
            img {
                width: 100%;
            }
            p {
                padding: 1rem 0rem;
                font-family: OpenSans-Regular;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 26px;
                margin: 0;
                /* or 162% */

                letter-spacing: 0.2px;

                color: #838383;
            }
        }

        .publicIcon {
            display: flex;
            padding-left: 0;

            li {
                // flex-grow: 1;
                a {
                    cursor: pointer;
                    padding-right: 20px;
                    img {
                        width: 20px;
                    }
                }
            }
        }
        label {
            font-family: FiraSans-Medium;
            font-style: normal;
            // font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            /* identical to box height */

            color: #1b1d21;

            ul {
                font-family: OpenSans-Regular;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                /* or 143% */

                color: #1b1d21;
            }
        }
        .logoForm {
            .formBoxSection {
                .emailInput {
                    box-sizing: border-box;
                    background: #b3bac512;
                    height: 40px;
                    width: 333.33px;
                    border: 1px solid #b3bac512;
                    width: 200px;
                    color: #000;
                    font-size: 14px;
                    line-height: 15px;
                    padding: 1.5rem;
                    outline: none;
                    font-family: OpenSans-Regular;
                    // font-style: italic;
                    font-weight: normal;
                    mix-blend-mode: normal;
                    // opacity: 0.1;
                    border-radius: 200px;
                }
                ::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #000;
                    font-family: OpenSans-Regular;
                    font-size: 14px;
                    line-height: 21px;
                }
                .contactpageBtn {
                    background: linear-gradient(
                        90deg,
                        #29abe2 -0.02%,
                        #29acd8 19.99%,
                        #29aebd 54.99%,
                        #28b292 99%,
                        #28b295 100%
                    );
                    border-radius: 50px;
                    color: #ffffff;
                    padding: 1rem;

                    font-family: OpenSans-Regular;
                    font-style: normal;
                    // font-weight: bold;
                    font-size: 18px;
                    line-height: 15px;
                    margin-top: 20px;
                    width: 200px;
                    letter-spacing: 0.15em;
                    font-weight: bold;
                }
            }
        }
       
    }
    .appstore {
        // display: flex;
        padding-left: 0px;
        padding-top: 2rem;
        .applogos{
            align-items: center;
    display: flex;
    list-style: none;
    padding-left: 0;
            li {
                // flex-grow: 1;
                display: flex;
                padding-right: 20px;
                width: 100%;
    height: 40px;
                a {
                    cursor: pointer;
                    // padding-right: 20px;
                    float: left;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        
    }
}

@media (min-width: 20em) and (max-width: 40em) and (orientation: portrait) {
    .footer {
        padding: 1rem;

        .footer-section {
            flex-direction: column;
            .Firstchild {
                img {
                    width: 60%;
                }
            }
        }
    }
}
@media (min-width: 30em) and (max-width: 60em) and (orientation: landscape) {
    .footer {
        padding: 1rem;

        .footer-section {
            flex-direction: column;
            .Firstchild {
                img {
                    width: 60%;
                }
            }
        }
    }
}
@media (min-width: 40em) and (max-width: 50em) and (orientation: portrait) {
    .footer {
        padding: 1rem;

        .footer-section {
            flex-direction: column;
            .Firstchild {
                img {
                    width: 60%;
                }
            }
        }
    }
}
